<template>
  <v-data-table
    class="ma-3"
    :headers="tableHeader"
    :items="tableData"
    :custom-sort="sortDataTableLevels"
    hide-default-footer
    :items-per-page="-1"
    fixed-header
    :height="short ? '300px' : '500px'"
    @click:row="tableRowClickFunction"
  >
    <!-- formate table headers -->
    <template v-slot:top>
        <v-dialog
            v-model="dialogEdit"
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="dialogTitle">Edit Campaign Data</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row v-for="(itemValue, itemKey) in tempEditData" :key="itemKey">
                    <v-col v-if="!(selectedLevels.includes(itemKey))"
                      cols="12"
                    >
                      <v-text-field
                        class="actionText"
                        v-model="tempEditData[itemKey]"
                        :label="itemKey"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-row class="ma-0 pa-0">
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn class="actionText" outlined color="black" @click="dialogEdit = false;">Cancel</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="actionText" outlined color="black" @click="editItemConfirm()">Save Changes</v-btn>
                  </v-col>
                </v-row>                
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="dialogTitle">Are you sure you want to delete this row?</v-card-title>
              <v-card-actions>
                <v-row class="ma-0 pa-0">
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn class="actionText" outlined color="black" @click="dialogDelete = false;">Cancel</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn class="actionText" outlined color="black" @click="deleteItemConfirm">Delete</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
           </v-card>
          </v-dialog> 
    </template>
    <template v-for="h in tableHeader" v-slot:[`header.${h.value}`]>
      <v-tooltip :key="h.value" bottom>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            :class="
              numericColumns.includes(h.value) ||
              currencyColumns.includes(h.value) ||
              percentageColumns.includes(h.value)
                ? 'headerCellFlighting'
                : 'headerCellFlighting headerStringCellFligthing'
            "
          >
            <v-menu v-model="filter_menus[h.value]" v-if="selectedLevels.includes(h.value) && filterable" :close-on-content-click="false" :close-on-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="ml-0 mr-1" v-bind="attrs" v-on="on">mdi-filter</v-icon>
              </template>
              <v-card class="filter_card">
                <v-card-title class="dialogTitle"><v-icon class="mx-1" color="black">mdi-filter</v-icon>{{ h.text }}</v-card-title>
                <v-row class="ma-0 pa-0" style="max-height: 200px; overflow-y: scroll;">
                  <v-col cols="12">
                    <v-select class="actionText" chip v-model="filter_vals[h.value]" :items="filter_all_vals[h.value]" multiple>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span class="actionText">{{ item }}</span>
                        </v-chip>
                        <v-chip v-if="index === 1">
                          <span class="actionText">{{ item }}</span>
                        </v-chip>
                        <v-chip v-if="index === 2">
                          <span class="actionText">{{ item }}</span>
                        </v-chip>
                        <span
                          v-if="index === 3"
                          class="grey--text text-caption"
                        >
                          (+{{ filter_vals[h.value].length - 3 }} others)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-btn class="actionText" outlined @click="cancel_filter(h.value);$nextTick(() => { filter_menus[h.value] = false; })">Cancel</v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn class="actionText" outlined @click="save_filter(h.value); filter_menus[h.value] = false;" :disabled="arraysEqual(filter_vals[h.value], filter_base[h.value]) || filter_vals[h.value].length == 0">Save</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-menu>
            
            <span class="headerCellContent">{{ h.text }}</span>
          </div>
        </template>
        <TooltipDescription :title="h.text" :desc_2="h.desc"></TooltipDescription>
      </v-tooltip>
    </template>

    <!-- format all plain numeric columns -->
    <template v-for="numCol in tableHeader" v-slot:[`item.${numCol.value}`]="{ item }">
      <div
        v-if="
          numericColumns.includes(numCol.value) ||
          currencyColumns.includes(numCol.value) ||
          percentageColumns.includes(numCol.value)
        "
        :key="numCol.value"
        :style="{ backgroundColor: update_color(item) }"
        class="numericCellFlighting ma-0 pa-1 px-2"
      >
        <div v-if="numericColumns.includes(numCol.value)" style="width: 80%;">
          <span :style="{color: update_text_color(item, numCol)}">
            {{
              item[numCol.value]
                ? item[numCol.value].toLocaleString("en-CA", {
                    minimumFractionDigits: 0, 
                    maximumFractionDigits: 0,
                  })
                : "n/a"
            }}
          </span>
        </div>
        <div v-else-if="currencyColumns.includes(numCol.value)" style="width: 80%;">
          <span :style="{color: update_text_color(item, numCol)}">
            {{
              item[numCol.value]
                ? item[numCol.value].toLocaleString("en-CA", {
                    style: "currency",
                    currency: "CAD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "n/a"
            }}
          </span>
        </div>
        <div v-else-if="percentageColumns.includes(numCol.value)" style="width: 80%;">
          <span :style="{color: update_text_color(item, numCol)}">
            {{
              item[numCol.value]
                ? item[numCol.value].toLocaleString("en-CA", {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "n/a"
            }}
          </span>
        </div>
      </div>
      <div v-else-if="numCol.value == 'actions'"
        :key="numCol.value"
        :style="{ backgroundColor: update_color(item) }"
        class="actionCell"
      >
        <div class="actionCellContent">
          <v-icon v-if="rowEditable"
          small
          color="black"
          class="mx-1"
          @click="{{ editOnClick(item) }}"
          :disabled="actions_locked"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            color="black"
            class="mx-1"
            @click="deleteOnClick(item)"
            :disabled="actions_locked"
          >
            mdi-delete
          </v-icon>
        </div>
      </div>
      <div
        v-else
        :key="numCol.value"
        :style="{ backgroundColor: update_color(item) }"
        class="stringCellFlighting ma-0 pa-1 px-2"
      >
        <span>
          {{ level_val(item, numCol.value) }}
        </span>
      </div>
    </template>
    <!-- format currency columns-->
    <!-- <template v-for="numCol in currencyColumns" v-slot:[`item.${numCol}`]="{ item }" >
            <div class="numericCell" :key="numCol.value">
            {{ item[numCol] ? item[numCol].toLocaleString("en-CA",{style: "currency", currency: "CAD", minimumFractionDigits: 0, maximumFractionDigits: 2}) : "n/a" }}
            </div>
        </template> -->
  </v-data-table>
</template>

<script>
import TooltipDescription from "@/components/Utility/TooltipDescription.vue";

let eidt_field_sort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  'channel'
];

export default {
  name: "DataLevelTable",
  props: [
    "tableHeader",
    "tableData",
    "selectedLevels",
    "tableRowClickFunction",
    "numericColumns",
    "currencyColumns",
    "percentageColumns",
    'rowEditable',
    "filterable",
    "filter_all_vals",
    "short",
    "actions_locked"
  ],
  components: {
    TooltipDescription,
  },
  mounted() {
    if(this.tableData != null){
      this.filter_vals = {}
      this.filter_base = {}
      this.filter_menus = {}
      for(let lvl of this.selectedLevels){
        this.$set(this.filter_vals, lvl, this.get_filter_vals(lvl));
        this.$set(this.filter_base, lvl, this.get_filter_vals(lvl));
        // this.filter_vals[lvl] = this.get_filter_vals(lvl);
        // this.filter_base[lvl] = this.get_filter_vals(lvl);
        this.$set(this.filter_menus, lvl, false);
      }
    }
  },
  data: () => ({
    dialogEdit: false,
    dialogDelete: false,
    tempEditData: [],
    filter_vals: {},
    filter_base: {},
    filter_menus: {}
  }),
  watch: {
    tableData(){
      // console.log(this.tableData)
      this.filter_vals = {}
      this.filter_base = {}
      this.filter_menus = {}
      for(let lvl of this.selectedLevels){
        // this.filter_vals[lvl] = this.get_filter_vals(lvl);
        // this.filter_base[lvl] = this.get_filter_vals(lvl);
        this.$set(this.filter_menus, lvl, false);
        this.$set(this.filter_vals, lvl, this.get_filter_vals(lvl));
        this.$set(this.filter_base, lvl, this.get_filter_vals(lvl));
      }
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  computed:{
  },
  methods: {
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      let a_sort = [...a].sort()
      let b_sort = [...a].sort()

      for (var i = 0; i < a.length; ++i) {
        if (a_sort[i] !== b_sort[i]) return false;
      }
      return true;
    },
    save_enabled: function(col){
      let equal = this.arraysEqual(this.filter_vals[col], this.filter_base[col]);
      return equal;
    },
    get_filter_vals: function(col){
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
      let filter_vals = []
      for(let row of this.tableData){
        if(row[col] != null){
          filter_vals.push(row[col]);
        }
      }
      filter_vals = filter_vals.filter(onlyUnique)
      return filter_vals
    },
    cancel_filter: function(col){
      this.filter_vals[col] = this.filter_base[col];
    },
    save_filter: function(){
      this.$emit('filter_data', this.filter_vals);
    },
    update_color: function (item) {
      // let grey_notches = ["#FFFFFF","#cccccc","#808080"];
      let grey_notches = [
        "#FFFFFF",
        "#F0F0F0",
        "#DCDCDC",
        "#D0D0D0",
        "#BEBEBE",
        "#A9A9A9",
      ];
      let null_count = 0;
      for (let level of this.selectedLevels) {
        if (item[level] == null) {
          null_count++;
        }
      }
      return grey_notches[null_count];
    },
    update_text_color: function (item, numCol){
      if(((numCol.value.includes('change'))) && item[numCol.value] && item[numCol.value] >= 0){
        return 'green';
      }
      else if(((numCol.value.includes('change'))) && item[numCol.value] && item[numCol.value] < 0){
        return 'red';
      }
      else{
        return 'black';
      }

      
    },
    level_val: function (item, col) {
      let idx = this.selectedLevels.indexOf(col);
      let hide = false;
      for (let i = this.selectedLevels.length - 1; i > idx; i--) {
        if (item[this.selectedLevels[i]] != null) {
          hide = true;
        }
      }
      if (hide) {
        return "";
      } else {
        return item[col];
      }
    },
    deleteOnClick: function(item) {
      this.itemToDelete = item
      this.dialogDelete=true;
    },
    closeDelete: function() {
      this.dialogDelete = false
    },
    deleteItemConfirm:function() {
      this.$emit('deleteItem', this.itemToDelete);
      this.dialogDelete = false
    },
    editOnClick: function(item) {
      this.editData={...item}

      const objectSorter = unsortedObject =>
        Object.keys(unsortedObject)  
          .sort(function(a, b) {
            return eidt_field_sort.indexOf(a.split(' ')[0]) - eidt_field_sort.indexOf(b.split(' ')[0]);
          })
          .reduce((finalObject, key) => {
            if(typeof(unsortedObject[key]) == 'number') {
              finalObject[key] = unsortedObject[key].toFixed(2);  
            } else {
              finalObject[key] = unsortedObject[key]
            }
            return finalObject;  
          }, {}); 

      let unsortedData = {...item}
      let sortedData = objectSorter(unsortedData)
      this.tempEditData = sortedData
      this.dialogEdit=true;
    },
    editItemConfirm: function() {    
      
      let change_payload = {channel: this.tempEditData["channel"]}
      let update_entries = {}
      for (const item in this.tempEditData) {
        if(item !== 'channel' &&
          Number(this.tempEditData[item]).toFixed(2) !== Number(this.editData[item]).toFixed(2)
        ) {
          update_entries[item] = this.tempEditData[item] - this.editData[item]
        }
      }
      change_payload['update_entries'] = update_entries
      this.$emit('editItem', change_payload)
      this.editData = this.tempEditData
      this.dialogEdit=false;
    },
    sortDataTableLevels(items, sortBy, sortDesc, locale) {
        if(sortBy.length == 0){
          sortBy = [...this.selectedLevels];
          sortDesc = [];
          for(let i = 0; i < this.selectedLevels.length; i++){
            sortDesc.push(false)
          }
        }
        // Define a comparator function
        const comparator = (a, b, key, descending) => {
          let valueA = a[key];
          let valueB = b[key];
          let result = 0;
          
          let isSelectedLevel = this.selectedLevels.includes(key);

          if (valueA == null && valueB == null) {
              result = 0;
          } else if (valueA == null) {
              result = isSelectedLevel ? -1 : 1;
              console.log(valueA, valueB, result)
              return result;
          } else if (valueB == null) {
              result = isSelectedLevel ? 1 : -1;
              console.log(valueA, valueB, result)
              return result;
          } else {
            if (typeof valueA === 'string' && typeof valueB === 'string') {
              result = valueA.localeCompare(valueB, locale, { numeric: true });
            } else if (typeof valueA === 'number' && typeof valueB === 'number') {
              result = valueA - valueB;
            } else if (valueA instanceof Date && valueB instanceof Date) {
              result = valueA - valueB;
            } else {
              result = String(valueA).localeCompare(String(valueB), locale, { numeric: true });
            }
          }
          // Adjust for descending order
          return descending ? -result : result;
        };
        
        if(this.selectedLevels.length > 1){
          const groupItems = (items, selectedLevels) => {
              // Helper function to create the hierarchical structure
              const createHierarchy = (items, levels) => {
                  if (levels.length === 0) {
                      return [];
                  }

                  const level = levels[0];
                  const grouped = items.reduce((acc, item) => {
                      const key = item[level];
                      if (key === undefined) return acc;

                      if (!acc[key]) {
                          acc[key] = [];
                      }
                      acc[key].push(item);
                      return acc;
                  }, {});

                  return Object.keys(grouped).map(key => {
                      const children = createHierarchy(grouped[key], levels.slice(1));
                      return {
                          parent: { ...grouped[key][0], [level]: key },
                          children
                      };
                  });
              };

              return createHierarchy(items, selectedLevels);
          };
          // Group and sort the items
          let groupedItems = groupItems(items, this.selectedLevels);

          // Function to sort the groups and their children
          const sortGroups = (groupedItems, sortBy, sortDesc) => {
              groupedItems.sort((a, b) => {
                  for (let i = 0; i < sortBy.length; i++) {
                      const key = sortBy[i];
                      const descending = sortDesc[i];

                      const result = comparator(a.parent, b.parent, key, descending);
                      if (result !== 0) return result;
                  }
                  return 0;
              });

              groupedItems.forEach(group => {
                  if (group.children && group.children.length > 0) {
                      // Recursively sort subgroups
                      sortGroups(group.children, sortBy, sortDesc);
                  }
              });
          };

          sortGroups(groupedItems, sortBy, sortDesc);

          // Flatten the groupedItems array recursively to get the final sorted list
          const flattenGroups = (groupedItems) => {
              return groupedItems.reduce((acc, group) => {
                  if (group.parent) acc.push(group.parent);
                  return acc.concat(flattenGroups(group.children));
              }, []);
          };

          const sortedItems = flattenGroups(groupedItems);

          return sortedItems;
        }
        else{
          // Sort the items array
          items.sort((a, b) => {
              for (let i = 0; i < sortBy.length; i++) {
                  const key = sortBy[i];
                  const descending = sortDesc[i];

                  const result = comparator(a, b, key, descending);
                  if (result !== 0) return result;
              }
              return 0; // If all comparisons are equal
          });
        }

        return items;
    },
    
  },
};
</script>

<style>

table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
  /* background: white; */
}

table > thead > tr > th:nth-child(1) {
  z-index: 4 !important;
}


.v-data-table-header > tr > th {
  min-width: 150px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px;
}

.v-data-table > .v-data-table__wrapper > table {
  cursor: pointer;
}

.headerCellFlighting {
  float: inline-start;
  max-width: 80%;
}

.headerCellContent {
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerStringCellFligthing {
  float: inline-start;
  max-width: 150% !important;
}

.numericCellFlighting {
  text-align: right;
  line-height: 48px;
  width: 100%;
  height: 100%;
  max-width: 250px;
}
.naCellFlighting {
  text-align: center;
  line-height: 48px;
  /* width: 100%; */
  height: 100%;
}
.stringCellFlighting {
  text-align: left;
  line-height: 48px;
  width: 150px;
  height: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.actionCell {
  display: flex;
  height: 100%;
  max-width: 100px;
  
  .actionCellContent {
    margin: auto 0;
    cursor: pointer;
  }
}

.filter_card{
  width: 400px;
}
.dialogTitle {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 1.2em !important;
}
.actionText {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
}
</style>
