<template>
  <v-app>
    <!-- 
      User Nav Drawer 
    -->
    <v-navigation-drawer
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      v-model="user_drawer"
      temporary
      absolute
      app
      color="primary"
      dark
      right
    >
      <template v-slot:prepend>
        <v-list-item one-line>
          <v-list-item-avatar style="background-color: #ffffff50">
            <img
              :src="$auth.user.picture"
              :alt="$auth.user.name"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">{{
              $auth.user.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar><v-icon>mdi-account-group-outline</v-icon></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">
              <div class="organization-title">
                <div class="organization-title-content">
                  {{user_orgs && user_orgs[$auth.user.org_id] ? user_orgs[$auth.user.org_id]['display_name'] : $auth.user.org_id}}
                </div>
                <v-tooltip left v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                      <v-icon dark color="#FFFFFF">mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Switch Organization
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider class="ma-0 pa-0 mx-6"></v-divider>
      <v-row class="ma-0 pa-0">
        <!-- <v-row class="ma-0 pa-0">
          <v-col cols="2">
            <v-icon>mdi-account-group-outline</v-icon>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols='10' class="ma-0 pt-2">
            <div class="organization-title">
              <div class="organization-title-content">
                {{ this.user_orgs && this.user_orgs[this.$auth.user.org_id] ? this.user_orgs[this.$auth.user.org_id]['display_name'] : this.$auth.user.org_id}}
              </div>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                    <v-icon dark color="#FFFFFF">mdi-autorenew</v-icon>
                  </v-btn>
                </template>
                <span class="tooltip_description" style="display: inline-block">
                  Switch Organization
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols='12' class="ma-0 pt-0">
            <div  class='organization-title organization-content'>
              {{ this.user_orgs && this.user_orgs[this.$auth.user.org_id] ? this.user_orgs[this.$auth.user.org_id]['display_name'] : this.$auth.user.org_id}}
            </div>
          </v-col>
        </v-row> -->
        <v-row class="ma-0 pa-0 pl-3" align="center">
          <span class="Brand-name-title">My Brands</span>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="ma-0 px-3 py-0">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn icon class="toolButton" v-on="on" @click="get_my_projects">
                  <v-icon color="#FFFFFF">mdi-folder-refresh-outline</v-icon>
                </v-btn>
              </template>
              <span class="tooltip_description" style="display: inline-block">
                Refresh My Brands
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-for="(env, idx) in environments" :key="idx" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pt-0">
            <span class="Environment-name-title" v-if="environments.length > 1">{{
              env
            }}</span>
          </v-col>
          <v-col
            cols="12"
            class="ma-0 py-0"
            v-for="(brand, jdx) in my_brands[env]"
            :key="jdx"
          >
            <v-switch
              class="ma-0 pa-0 Brand-name-title"
              dense
              :label="brand[1]"
              v-model="tool_state"
              :value="brand[0]"
              @click="tool_state = brand[0]"
            ></v-switch>
          </v-col>
        </v-row>
        <!-- <v-col cols="12" style="color: white; text-decoration: underline;">
          <span>Environments</span>
        </v-col>
        <v-col cols="12" class="ma-0 py-0">
          <v-switch class="ma-0 pa-0" dense label="Demo Mode" v-model="tool_state" value="demo" @click="tool_state = ['demo'];"></v-switch>
        </v-col>
        <v-col cols="12" class="ma-0 py-0">
          <v-switch class="ma-0 pa-0" label="VMC-PF" v-model="tool_state" value="VMC-PF" @click="tool_state = ['VMC-PF'];"></v-switch>
        </v-col>
        <v-col cols="12" class="ma-0 py-0">
          <v-switch class="ma-0 pa-0" label="VMC-Ric" v-model="tool_state" value="VMC-Ric" @click="tool_state = ['VMC-Ric'];"></v-switch>
        </v-col>
        <v-col cols="12" class="ma-0 py-0">
          <v-switch class="ma-0 pa-0" label="VMC-Ric Updated" v-model="tool_state" value="VMC-Ric-U" @click="tool_state = ['VMC-Ric-U'];"></v-switch>
        </v-col> -->
      </v-row>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="docma-text-navigation-logout" block @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- 
      APP Bar
     -->
    <v-app-bar
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      app
      color="primary"
      dark
      height="45px"
      extension-height="48px"
      class="ma-0 pa-0"
    >
      <v-row class="ma-0 pa-0 px-3" max-height="100%">
        <v-col cols="4" class="ma-0 pa-0" style="max-height: 45px">
          <v-img
            :src="require(`${main_logo_inverterd}`)"
            contain
            max-width="150px"
            max-height="30px"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-0 pa-0 docma-text-navigation-bar-btn"
          text
          plain
          dark
          @click="user_drawer = true"
        >
          <span class="docma-text-navigation-bar-text"
            >Welcome {{ $auth.user.given_name }}</span
          >
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
        <v-divider vertical color="white" class="ma-0 pa-0 mx-3"></v-divider>
        <v-btn class="ma-0 pa-0 docma-text-navigation-bar-btn" text plain dark disabled>
          <span class="docma-text-navigation-bar-text">Help</span>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-divider vertical color="white" class="ma-0 pa-0 mx-3"></v-divider>
        <v-btn
          class="ma-0 pa-0 docma-text-navigation-bar-btn"
          text
          plain
          dark
          disabled
          @click="feedback = true"
        >
          <span class="docma-text-navigation-bar-text">Feedback</span>
          <v-icon>mdi-comment-quote-outline</v-icon>
        </v-btn>
      </v-row>

      <template v-slot:extension>
        <v-row class="ma-0 pa-0" style="height: 100%">
          <v-col cols="10" class="ma-0 pa-0 px-3" style="max-height: 48px">
            <v-tabs v-model="mainTab" align-tabs="start" dark>
              <v-tab class="docma-tool-navigation-bar" to="/audience">Audiences  <v-icon class="ml-2">mdi-professional-hexagon</v-icon></v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/planning">Campaign Planning <v-icon class="ml-2">mdi-professional-hexagon</v-icon></v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/performance">Performance</v-tab>
            </v-tabs>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col cols="auto">
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-col> -->
          <v-col cols="auto">
            <v-icon>mdi-bell</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <!-- 
      Feedback
    -->
    <v-overlay v-model="feedback">
      <FeedbackCard v-on:close="feedback = false"></FeedbackCard>
    </v-overlay>
    <!-- 
      APP Content
     -->
    <v-main
      style="
        height: calc(100vh-93px);
        max-height: calc(100vh-93px);
        overflow-y: hidden;
        overflow-x: hidden;
      "
      v-if="environment_enabled"
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import FeedbackCard from "./views/FeedbackCard.vue";
import api_caller from "./javascript/data_info.js";
// import PerformanceBasePage from './components/Performance/PerformanceBasePage.vue';

export default {
  name: "App",

  components: {
    FeedbackCard,
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.user_first_name = this.$auth.user.given_name;
      this.user_full_name = this.$auth.user.name;
      this.get_my_projects();
    }
  },
  updated() {
    if (this.$auth.isAuthenticated && !this.brands_pulled) {
      this.get_my_projects();
      this.get_my_orgs();
    }
  },
  watch: {
    tool_state: {
      handler: function () {
        this.$project.set_val(this.tool_state);
        this.reset_environment();
      },
    },
  },
  data: () => ({
    main_logo: "./assets/docma-logo-dark.svg",
    main_logo_inverterd: "./assets/docma-logo-white-red.svg",

    logged_in: false,
    user_drawer: false,
    feedback: false,
    user_orgs: {},

    user_first_name: "",
    user_full_name: "",

    tool_state: null,

    my_brands: {},
    environments: [],

    rating: 5,
    rating_follow_up: true,

    rating_success: false,
    rating_error: false,

    mainTab: 2,

    environment_enabled: true,

    brands_pulled: false,
  }),
  methods: {
    reset_environment: function () {
      this.environment_enabled = false;
      setTimeout(() => (this.environment_enabled = true), 500);
    },
    get_my_projects: async function () {
      let token = await this.$auth.getTokenSilently();
      let projects = await api_caller
        .get_saved_projects(token)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (!projects) {
        return;
      }
      this.environments = Object.keys(projects);
      this.my_brands = projects;
      let project_exists = false;
      let existing_project = null;
      for(let env of this.environments){
        for(let brand of projects[env]){
          if(brand[0] === this.tool_state){
            project_exists = true;
            existing_project = brand;
          }
        }
      }
      if (this.environments.length > 0 && project_exists) {
        this.tool_state = existing_project[0];
        this.reset_environment();
      }
      else if(this.environments.length > 0){
        this.tool_state = this.my_brands[this.environments[0]][0][0];
      }
      this.brands_pulled = true;
    },
    logout: function () {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    get_my_orgs: async function () {
      let token = await this.$auth.getTokenSilently();
      let userOrgs = await api_caller
        .get_user_orgs(token, this.$auth.user.sub)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if(userOrgs) {
        this.user_orgs = userOrgs
      }
    },
    switchOrg: function () {
      this.$auth.loginWithRedirect()
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:200,400,600,800");
@import url("https://fonts.googleapis.com/css?family=Amiko:400,600,700");
.docma-tool-navigation-bar {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 0.9em !important;
}
.docma-text-navigation-bar-text {
  font-family: "Amiko" !important;
  font-size: 0.7em;
}
.docma-text-navigation-bar-btn {
  height: 30px !important;
}
.docma-text-navigation-logout {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 1em !important;
}
.docma-text-navigation-user {
  font-family: "Poppins" !important;
  font-weight: 200 !important;
  font-size: 1.1em !important;
}
.Environment-name-title {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
  color: white;
}
.Brand-name-title {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 1em !important;
  color: white;
}
.docma-text-rating-main-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 1.2em;
}
.docma-text-rating-title {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 0.9em;
}
.docma-text-rating-text {
  font-family: "Poppins" !important;
  font-weight: 200;
  font-size: 1.1em;
}
.loginFormAlerts {
  font-family: "Poppins" !important;
  position: absolute;
  width: 100%;
  z-index: 4;
}
.organization-title {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 0.7em !important;
  color: white;
  display: flex;
  max-width: 200px;
  align: left;

  .organization-title-content {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    margin: auto;
  }
}
.organization-content {
  font-size: 1em !important;
  padding-bottom: 12px;
}

/deep/ .v-toolbar__content {
  padding: 0px !important;
}
/deep/ .v-toolbar__extension {
  padding: 0px !important;
}
</style>
