<template>
    <v-row class="ma-0 pa-0" style="">
        <v-col cols="12">
            <v-menu
            ref="date_range_picker"
            v-model="date_range_picker"
            :close-on-content-click="false"
            :return-value.sync="date_range"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                class="toolbar_selector"
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar-range"
                readonly
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date_range"
                :min="min_start_date"
                :max="max_end_date"
                no-title
                scrollable
                range
                color="black">
                <v-spacer></v-spacer>
                <v-btn
                class="toolbar_date_btn"
                text
                color="primary"
                @click="date_range_picker = false"
                >
                Cancel
                </v-btn>
                <v-btn
                class="toolbar_date_btn"
                text
                color="primary"
                @click="$refs.date_range_picker.save(date_range); commit_date_range()"
                >
                OK
                </v-btn>
            </v-date-picker>
            </v-menu>
        </v-col>
        <!-- <v-col cols="6">
            <v-menu
            ref="start_date_picker"
            v-model="start_date_picker"
            :close-on-content-click="false"
            :return-value.sync="s_date"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                class="toolbar_selector"
                v-model="s_date"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="s_date"
                :min="min_start_date"
                :max="max_end_date"
                no-title
                scrollable
                range>
                <v-spacer></v-spacer>
                <v-btn
                class="toolbar_date_btn"
                text
                color="primary"
                @click="start_date_picker = false"
                >
                Cancel
                </v-btn>
                <v-btn
                class="toolbar_date_btn"
                text
                color="primary"
                @click="$refs.start_date_picker.save(s_date)"
                >
                OK
                </v-btn>
            </v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="6">
            <v-menu
            ref="end_date_picker"
            v-model="end_date_picker"
            :close-on-content-click="false"
            :return-value.sync="e_date"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                class="toolbar_selector"
                v-model="e_date"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="e_date"
                :min="min_start_date"
                :max="max_end_date"
                no-title
                scrollable>
                <v-spacer></v-spacer>
                <v-btn
                class="toolbar_date_btn"
                text
                color="primary"
                @click="end_date_picker = false"
                >
                Cancel
                </v-btn>
                <v-btn
                class="toolbar_date_btn"
                text
                color="primary"
                @click="$refs.end_date_picker.save(e_date)"
                >
                OK
                </v-btn>
            </v-date-picker>
            </v-menu>
        </v-col> -->
    </v-row>
</template>

<script>
export default {
    name: 'DateRange',
    props: [
        'start_date',
        'end_date',
        'min_start_date',
        'max_end_date'
    ],
    mounted(){
    },
    computed: {
      dateRangeText () {
        return this.date_range.join(' ~ ')
      },
    },
    data: () => ({
        start_date_picker: false,
        end_date_picker: false,
        date_range_picker: false,
        s_date: null,
        e_date: null,
        date_range: []
    }),
    methods: {
        commit_date_range: function(){
            this.$emit('date_update',this.date_range);
        }
    },
    watch: {
        // date_range: {
        //     immediate: false,
        //     handler: function (newVal, oldVal) {
        //         if(oldVal != null){
        //             this.$emit('date_update',newVal);
        //         }
        //     }
        // },
        s_date: {
            immediate: false,
            handler: function (newVal, oldVal) {
                if(oldVal != null){
                    console.log('start');
                    console.log(newVal);
                    // this.$emit('start_date_update',newVal);
                }
            }
        },
        e_date: {
            immediate: false,
            handler: function(newVal, oldVal){
                if(oldVal != null){
                    console.log('end')
                    console.log(newVal);
                    // this.$emit('end_date_update',newVal);
                }
            },
        },
        start_date: {
            immediate: true,
            handler: function () {
                this.s_date = this.start_date;
                this.date_range = [this.start_date, this.end_date];
            },
        },
        end_date: {
            immediate: true,
            handler: function () {
                this.e_date = this.end_date;
                this.date_range = [this.start_date, this.end_date];
            },
        }
    },
}
</script>

<style scoped>
    .toolbar_date_btn{
        font-family: 'Poppins';
        font-weight: 600;
    }
</style>