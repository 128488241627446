<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%;">
      <v-row class="ma-0 pa-0" style="width: 100%; height: 100%" align="center" justify="space-around">
        <v-col cols="12" style="width: 100%; height: 100%;">
          <v-row class="ma-0 pa-0"> 
            <v-col cols="10" class="ma-0 pa-0" v-if="!loading">
              <v-chip label class="data_breakdown_chip" v-for="(opt) in optimizations" :key="opt.ID" color="black" :outlined="my_optimization !== opt.ID" @click="my_optimization = opt.ID">
                {{opt.Name}}
              </v-chip>
              <v-menu
                v-model="add_optimization_menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: ontooltip }">
                      <v-btn icon elevation="0" v-on="ontooltip">
                        <v-icon color="black" :disabled="optimizations.length > 4" v-bind="attrs" v-on="on" >mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <TooltipDescription title="Add Optimization" desc_2=""></TooltipDescription>
                  </v-tooltip>
                  <!-- <v-icon class="mx-3" color="black" v-bind="attrs" v-on="on" :disabled="optimizations.length > 4">mdi-plus</v-icon> -->
                </template>
                <v-card>
                  <v-card-title class="optimization_create_title">Create Optimization</v-card-title>
                  <v-card-text class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-text-field v-model="new_optimization_title" class="ma-0 pa-0 optimization_calculator_action" label="Optimization Name"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <span class="optimization_calculator_action">Risk Level</span>
                      </v-col>
                      <v-col cols="12" class="ma-0">
                        <v-radio-group v-model="new_optimization_risk" class="ma-0 pa-0 optimization_calculator_action">
                          <v-radio value="low" label="Low Risk"></v-radio>
                          <v-radio value="mid" label="Mid Risk"></v-radio>
                          <v-radio value="high" label="High Risk"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="optimization_calculator_action" text @click="new_optimization_title=''; new_optimization_risk='low'; add_optimization_menu = false"> Cancel </v-btn>
                    <v-btn class="optimization_calculator_action" text @click="add_optimization_menu = false; add_new_optimization();" :disabled="new_optimization_title === ''"> Create </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" v-if="!loading">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon color="black" v-on="on" :disabled="!save_optimizations" @click="save_optimization">mdi-content-save-outline</v-icon>
                </template>
                <TooltipDescription title="Save Optimizations" desc_2="Overwrite existing Optimizations. Unless you have built optimizations any settings will be permanently overwritten."></TooltipDescription>
              </v-tooltip>
            </v-col>
            <v-col cols="12"  v-if="loading">
              <v-row class="ma-0 pa-0" justify="center" :align="'center'" style="height: 100%">
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-progress-circular
                    size="100"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0" v-if="!loading">
              <v-row class="ma-0 pa-0">
                <v-col cols="9" style="height: 500px; overflow-y: scroll;">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="py-1">
                      <v-text-field class="optimization_calculator_action" v-model="my_optimization_name" :disabled="optimization_channels.length==0 || lock_changes" @change="update_optimization_name"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" :disabled="optimizations.length<2 || lock_changes" @click="delete_optimization">mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Delete Optimization" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0"  v-for="channel, idx in optimization_channels" :key="idx" align="center">
                    <v-col cols="auto" class="ma-0 px-0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" :disabled="channel['lock'] || lock_changes">mdi-chart-bell-curve-cumulative</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Response Curve" desc_2=""></TooltipDescription>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="2" class="ma-0 pa-3" align="left"><span class="optimization_calculator_channel">{{channel['channel']}}</span></v-col>
                    <v-col cols="2" class="ma-0 pa-3" align="left">
                      <span class="optimization_calculator_spend">
                        {{
                        channel['spend'].toLocaleString("en-CA", {
                          style: "currency",
                          currency: "CAD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field v-if="!channel['lock']" v-model="channel['max_change']" class="mx-3 optimization_calculator_action" label="Max Change (%)" :disabled="channel['lock'] || lock_changes" type="number" suffix="%" @change="save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Max Change (%)" disabled type="number" suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field v-if="!channel['lock']" :value="(((channel['max_change'] / 100) * channel['spend']) + channel['spend']).toLocaleString('en-CA', {minimumFractionDigits: 0,maximumFractionDigits: 2})" class="mx-3 optimization_calculator_action" label="Max Change ($)" :disabled="channel['lock'] || lock_changes" prefix="$" @change="channel_spend_change($event,channel); save_optimizations=true;"></v-text-field>
                      <v-text-field v-else class="mx-3 optimization_calculator_action" value="0" label="Max Change (%)" disabled type="number" suffix="%"></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="ma-0 pa-0">
                      <v-tooltip top v-if="channel['lock']">
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" @click="channel['lock'] = !channel['lock']; save_optimizations=true;" :disabled="lock_changes">mdi-lock</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Investment Locked" desc_2=""></TooltipDescription>
                      </v-tooltip>
                      <v-tooltip top v-if="!channel['lock']">
                        <template v-slot:activator="{ on }">
                          <v-btn icon elevation="0" v-on="on">
                            <v-icon color="black" @click="channel['lock'] = !channel['lock']; save_optimizations=true;" :disabled="lock_changes">mdi-lock-open-outline</v-icon>
                          </v-btn>
                        </template>
                        <TooltipDescription title="Investment Unlocked" desc_2=""></TooltipDescription>
                      </v-tooltip>
                      <!-- <v-switch prepend-icon="mdi-lock" v-model="channel['lock']" :disabled="lock_changes"> 
                      </v-switch> -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="3">
                  <span class="optimization_setup_title">Total Budget</span>
                  <v-row class="ma-0 pa-0" align="center" justify="space-between">
                    <v-col cols="7" class="ma-0 pa-0">
                      <v-text-field class="optimization_calculator_action" v-model="optimization_total_budget" :disabled="optimization_channels.length==0 || lock_changes" type="number" prefix="$" @change="update_opt_setup_vars"></v-text-field>
                    </v-col>
                    <v-col cols="auto" class="ma-0 pa-0">
                      <v-icon color="black">mdi-plus-minus-variant</v-icon>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0">
                      <v-text-field class="optimization_calculator_action" v-model="optimization_total_budget_pm" :disabled="optimization_channels.length==0 || lock_changes" type="number" suffix="%" @change="update_opt_setup_vars"></v-text-field>
                    </v-col>
                  </v-row>
                  <span class="optimization_setup_title">Optimization Strategy</span>
                  <v-radio-group class="optimization_calculator_action" v-model="optimization_strategy" :disabled="optimization_channels.length==0 || lock_changes" @change="update_opt_setup_vars">
                    <v-radio label="ROAS" value="roas"></v-radio>
                    <v-radio label="Attribution" value="attribution"></v-radio>
                    <v-radio label="Ladder" value="ladder" disabled></v-radio>
                  </v-radio-group>
                  <span class="optimization_setup_title">Optimization Calculator</span>
                  <v-radio-group class="optimization_calculator_action" v-model="optimization_calculator" :disabled="optimization_channels.length==0 || lock_changes" @change="update_opt_setup_vars">
                    <v-radio label="Bulk" value="bulk"></v-radio>
                    <v-radio label="Flighted" value="flighting" disabled></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  // import DateRange from "@/components/Utility/DateRange.vue";
  // import performance_data_header from "@/input_data/performance_table_header.json";
  // import PredictionPieChart from "@/components/Planning/PlanningSetup/Charts/PredictionPieChart.vue"
  import TooltipDescription from "@/components/Utility/TooltipDescription.vue";
  // import ResponseCurve from "@/components/Planning/PlanningSetup/Charts/ResponseCurve.vue"
  // import PredictionStatistic from "./Charts/PredictionStatistic.vue"

  // import PlanningOptimizationSetup from "./PlanningOptimizationSetup.vue"

  import api_caller from "@/javascript/planning.js"

  export default {
    name: "PlanningOptimization",
    props: [
      'data_version',
      'data_levels',
      'project_date_range',
      'lock_changes',
      'project_plan_id'
    ],
    components: {
      TooltipDescription,
    },
    data: () => ({
      loading: true,
      add_optimization_menu: false,

      optimizations: [],
      my_optimization: null,
      my_optimization_name: '',

      optimization_setups: {},
      optimization_total_budget: 0,
      optimization_total_budget_pm: 0,
      optimization_strategy: 'bulk',
      optimization_calculator: 'roas',
      optimization_channels: [],

      new_optimization_title: '',
      new_optimization_risk: 'low',

      save_optimizations: false
    }),
    mounted(){
      this.reset_table()
    },
    watch: {
      project_plan_id: function(){
        this.reset_table();
      },
      my_optimization: function(){
        if(this.my_optimization in this.optimization_setups){
          let opt_setup = this.optimization_setups[this.my_optimization];

          this.optimization_total_budget = opt_setup['total_budget'];
          this.optimization_total_budget_pm = opt_setup['total_budget_shift'];
          this.optimization_strategy = opt_setup['strategy'];
          this.optimization_calculator = opt_setup['calculator'];
          this.optimization_channels = opt_setup['optimization'];
          this.my_optimization_name = opt_setup['title'];
        }
      }
    },
    computed: {
    },
    methods: {
      update_optimization_name: function(){
        this.optimization_setups[this.my_optimization]['title'] = this.my_optimization_name;
        for(let idx = 0 ; idx < this.optimizations.length; idx ++){
          if(this.optimizations[idx]['ID'] == this.my_optimization){
            this.optimizations[idx]['Name'] = this.my_optimization_name;
          }
        }
        this.save_optimizations = true;
      },
      channel_spend_change: function(item, channel){
        let new_spend = item.replaceAll(',', ''); 
        new_spend = parseFloat(new_spend) - channel['spend'];
        channel['max_change'] = (new_spend / channel['spend']) * 100;
      },
      reset_table: async function(){
        this.loading = true;
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString()
        }
        let data = await api_caller.get_optimization_info(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return 'False'
                                });
        this.assign_optimization(data);
      },
      add_new_optimization: async function(){
        this.loading = true;
        if(this.save_optimizations){
          await this.save_optimization(false)
        }
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimization_title': this.new_optimization_title,
          'optimization_risk': this.new_optimization_risk
        }
        let data = await api_caller.add_new_optimization(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return 'False'
                                });
        this.assign_optimization(data);
        this.new_optimization_title = '';
        this.new_optimization_risk = 'low';
      },
      delete_optimization: async function(){
        this.loading = true;
        if(this.save_optimizations){
          await this.save_optimization(false)
        }
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimization_id': this.my_optimization
        }
        let data = await api_caller.delete_optimization(token,payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return false;
                                });
        this.assign_optimization(data);
      },
      update_opt_setup_vars: function(){
        this.optimization_setups[this.my_optimization]['total_budget'] = this.optimization_total_budget;
        this.optimization_setups[this.my_optimization]['total_budget_shift'] = this.optimization_total_budget_pm;
        this.optimization_setups[this.my_optimization]['strategy'] = this.optimization_strategy;
        this.optimization_setups[this.my_optimization]['calculator'] = this.optimization_calculator;
        this.save_optimizations=true;
      },
      save_optimization: async function(reload_data=true){
        let optimizations = [];
        for(let id of Object.keys(this.optimization_setups)){
          optimizations.push(this.optimization_setups[id]);
        }
        console.log(optimizations)
        this.loading = true;
        let token = await this.$auth.getTokenSilently();
        let payload = {
          'project_id': this.$project.get_val(),
          'project_plan_id': this.project_plan_id.toString(),
          'optimizations': optimizations
        }
        console.log(optimizations);
        console.log(payload);
        let data = await api_caller.update_optimization(token, payload).then((resp)=>{
                                  if(!resp['success']){
                                    return false
                                  }
                                  return resp['data']
                                }).catch((e)=>{
                                    console.log(e)
                                    return false;
                                });
        if(reload_data){
          this.assign_optimization(data);
          this.loading=false;
        }
      },
      assign_optimization: function(data){
        if(data){
          this.performanceData = data;
          let opt_setups = this.performanceData['setups'];
          this.optimization_setups = {}
          for(let setup of opt_setups){
            this.optimization_setups[setup['id']] = setup
          }

          this.optimizations = this.performanceData['optimizations'];
          this.my_optimization = this.optimizations[0]['ID'];

          this.loading = false;
        }
        else{
          this.performanceData = null;
          this.loading = true;
        }
      }
    }
  };
  </script>
  
  <style>
  .data_breakdown_chip {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 0.85em;
    width: 150px;
  }
  .optimization_setup_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
    font-size: 0.85em;
  }
  .optimization_create_title {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
  }
  .optimization_calculator_channel {
    font-family: 'Poppins' !important;
    font-weight: 600 ;
  }
  .optimization_calculator_spend {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  .optimization_calculator_action {
    font-family: 'Poppins' !important;
    font-weight: 400 ;
  }
  </style>
  