<template>
    <v-container class="px-6 pt-0 pb-3" style="max-width: 100%">
    <v-row class="ma-0 pa-0" style="width: 100%; height: 100%">
        <v-col cols="auto" class="ma-0 pa-0">
            <v-chip label class="data_breakdown_chip" :outlined="selected_page != 'saved'" @click="selected_page = 'saved'" color="black">My Plans</v-chip>
            <v-chip label class="data_breakdown_chip" :outlined="selected_page != 'new_plan'" @click="selected_page = 'new_plan'" color="black">Create New Plan </v-chip>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" v-if="page_loading">
            <v-row class="ma-0 pa-0" style="width: 100%; height: 100%;" align="center" justify="center">
                <v-col cols="auto">
                    <v-progress-circular color="black" size="100" indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" v-else-if="selected_page == 'saved'">
            <v-card style="max-height: 60vh; overflow-y: scroll">
                <v-card-title>
                    <v-icon class="ml-0 mr-3" color="black">mdi-content-save-outline</v-icon>
                    <span class="toolbar_title">My Plans</span>
                </v-card-title>
                <v-row class="ma-0 pa-0">
                    <v-col cols="12" v-if="available_campaign_plans.length == 0">
                        <v-row class="ma-0 pa-0" align="center" justify="center">
                            <v-col cols="auto">
                                <span>No Saved Plans</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-list three-line>
                            <template v-for="(item, index) in available_campaign_plans">
                                <v-list-item :key="index">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <span>{{item.plan_name}}</span>
                                                    <span v-if="item.status === 'TEMPORARY'"> ({{ item.status }})</span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>Last Edit: {{item.created_time}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>Created: {{item.created_time}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="px-1" v-if="item.status === 'TEMPORARY'">
                                            <v-icon color="black">mdi-content-save-outline</v-icon>
                                        </v-col>
                                        <v-col cols="auto" class="px-1">
                                            <v-icon @click="delete_saved_plan(item.plan_id)" color="black">mdi-trash-can-outline</v-icon>
                                        </v-col>
                                        <v-col cols="auto" class="px-1">
                                            <v-icon color="black" @click="open_saved_plan(item.plan_id, item.status)">mdi-open-in-app</v-icon>
                                        </v-col>
                                    </v-row>
                                    
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" v-else-if="selected_page == 'new_plan'">
            <v-card style="max-height: 60vh; overflow-y: scroll">
                <v-card-title>
                    <v-icon class="ml-0 mr-3" color="black">mdi-content-save-plus-outline</v-icon>
                    <span class="toolbar_title">Create New Plan</span>
                </v-card-title>
                <v-row style="height: 85%; width: 100%" class="ma-0 pa-0">
                    <v-col col="12" style="height: 85%;">
                    <v-col cols="12" >
                        <v-select
                        class="toolbar_selector"
                        disabled
                        :value="data_version"
                        label="Data Version"
                        hint="The data version of the blocking chart"
                        persistent-hint
                        :items="[data_version]"
                        @change="reset_table()"
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            class="toolbar_selector"
                            v-model="selected_levels"
                            :items="available_data_levels"
                            label="Levels"
                            multiple
                            hint="Pick the level of granularity you wish to view the data."
                            persistent-hint
                            chips
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <span>Blocking Chart Date Range</span>
                        <v-tabs
                        v-model="prediction_range_tab"
                        align-tabs="start"
                        @change="on_prediction_tab_change($event)"
                        >
                            <v-tab class="prediction_range_tab" key="predefined">Predefined</v-tab>
                            <v-tab class="prediction_range_tab" key="customized">Customized</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="prediction_range_tab">
                        <v-tab-item>
                            <v-row class="ma-0 pa-0" align="center" justify="space-around">
                            <v-col cols="12">
                                <v-radio-group
                                v-model="predefined_radio_val"
                                @change="on_predefined_input_change($event)"
                                row
                                >
                                <v-radio label="30 days" value="val_1"></v-radio>
                                <v-radio label="60 days" value="val_2"></v-radio>
                                <v-radio label="90 days" value="val_3"></v-radio>
                                </v-radio-group>
                            </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <DateRange
                            :start_date="predict_start_date"
                            :end_date="predict_end_date"
                            :min_start_date="predict_min_start_date"
                            :max_end_date="max_end_date"
                            v-on:start_date_update="update_predict_start_date"
                            v-on:end_date_update="update_predict_end_date"
                            ></DateRange>
                        </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                    <v-col cols="12">
                        <span>Data to Copy</span>
                        <DateRange
                        :start_date="copy_start_date"
                        :end_date="copy_end_date"
                        :min_start_date="project_date_range['min_start_date']"
                        :max_end_date="project_date_range['max_end_date']"
                        v-on:start_date_update="update_copy_start_date"
                        v-on:end_date_update="update_copy_end_date"
                        ></DateRange>
                    </v-col>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="ma-0 pa-0" cols="12" justify="space-around">
                            <v-col cols="6">
                                <v-btn outlined color="black" @click="copy_range_on_click" class="copy_button">
                                    <span>Copy</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>
  
<script>
  import DateRange from '@/components/Utility/DateRange.vue'

  import planning_api_caller from "@/javascript/planning.js";
  import { ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE } from "@/constants/constants";

  export default {
    name: "PlanningPrediction",
    props: ["project_id","data_version", "data_levels", "project_date_range", "reset"],
    components: {
        DateRange
    },
    data: () => ({
        selected_page: 'saved',
        page_loading: true,

        delete_plan_menu: false,


        prediction_range_tab: 0,
        campaign_plan_tab: 0,
        predefined_radio_val: "val_1",
        predict_length: 30,

        start_date: null,
        end_date: null,

        predict_start_date: null,
        predict_end_date: null,

        copy_start_date: null,
        copy_end_date: null,

        predict_min_start_date: null,
        max_end_date: null,
        toolbar_visible: false,

        available_campaign_plans: []
    }),
    async mounted(){
        this.selected_levels = this.data_levels["selected_levels"];
        if(this.selected_levels.length == 0){
            this.selected_levels = this.data_level['data_levels'][0];
        }
        this.available_data_levels = this.data_levels['data_levels'];
        let predict_start_date = new Date();
        predict_start_date.setDate(predict_start_date.getDate() + 1);
        let formatted_start_date = predict_start_date.toISOString().slice(0, 10);

        this.predict_start_date = formatted_start_date;
        this.predict_end_date = formatted_start_date;

        this.predict_min_start_date = formatted_start_date;
        this.copy_start_date = this.project_date_range["start_date"];
        this.update_copy_range(true);
        await this.get_available_campaign_plans();
        this.page_loading = false;
    },
    watch: {
    },
    computed: {
    },
    methods: {
        // update the date range to be used to copy the breakdown data
        // forward_update: whether to update end start base on start date or update start date based on end date
        update_copy_range(forward_update) {
            let tmpDate = null;
            if (forward_update) {
                tmpDate = new Date(this.copy_start_date);
                tmpDate.setDate(tmpDate.getDate() + this.predict_length);
                let maxEndDate = new Date(this.project_date_range["max_end_date"]);
                if (tmpDate > maxEndDate) {
                tmpDate = maxEndDate;
                }
                this.copy_end_date = tmpDate.toISOString().slice(0, 10);
            } else {
                tmpDate = new Date(this.copy_end_date);
                tmpDate.setDate(tmpDate.getDate() - this.predict_length);
                let minStartDate = new Date(this.project_date_range["min_start_date"]);
                if (tmpDate < minStartDate) {
                tmpDate = minStartDate;
                }
                this.copy_start_date = tmpDate.toISOString().slice(0, 10);
            }
        },
        update_copy_start_date(date) {
            if (date != this.copy_start_date) {
                this.copy_start_date = date;
                this.update_copy_range(true);
            }
        },
        update_copy_end_date(date) {
            if (date != this.copy_end_date) {
                this.copy_end_date = date;
                this.update_copy_range(false);
            }
        },
        update_predict_start_date(date) {
            this.predict_start_date = date;
            this.update_custom_predict_length();
            this.update_copy_range(true);
        },
        update_predict_end_date(date) {
            this.predict_end_date = date;
            this.update_custom_predict_length();
            this.update_copy_range(true);
        },
        on_prediction_tab_change(tab_idx) {
            if (tab_idx == 0) {
                this.predict_length = 30;
            } else {
                this.update_predict_length();
            }
        },
        on_predefined_input_change(val) {
            switch (val) {
                case "val_1":
                // 30 day range
                this.predict_length = 30;
                break;
                case "val_2":
                // 2 month range
                this.predict_length = 60;
                break;
                case "val_3":
                // 3 month range
                this.predict_length = 90;
                break;
            }
            this.update_copy_range(true);
        },
        copy_range_on_click: async function () {
            this.start_date = this.copy_start_date;
            this.end_date = this.copy_end_date;

            let campaign_plan_payload = {
                project_id: this.project_id,
                data_version: this.data_version,
                start_date: this.start_date,
                end_date: this.end_date,
                ad_unit: this.selected_levels
            };

            let token = await this.$auth.getTokenSilently();
            let result = await planning_api_caller
                .create_campaign_plan(token, campaign_plan_payload)
                .then((resp) => {
                if (resp["success"]) {
                    return resp["data"];
                }
                return false;
                })
                .catch((e) => {
                console.log("error", e);
                return false;
                });
            if (result && result["campaign_plan_id"]) {
                this.campaign_plan_id = result["campaign_plan_id"];
                this.$emit("update_campaign_plan_id", result["campaign_plan_id"], false);

                // await this.reset_table();
            } else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }

            this.$emit("date_update", { start_date: this.start_date, end_date: this.end_date });
        },
        get_available_campaign_plans: async function () {
            let payload = {
                project_id: this.project_id,
            };

            let token = await this.$auth.getTokenSilently();
            let result = await planning_api_caller
                .get_campaign_plan(token, payload)
                .then((resp) => {
                if (resp["success"]) {
                    return resp["data"];
                }
                return false;
                })
                .catch((e) => {
                console.log("error", e);
                return false;
                });
            if (result && result["available_campaigns"]) {
                this.available_campaign_plans = result["available_campaigns"];
                console.log(this.available_campaign_plans)
            } else {
                this.$emit("triggerAlert", ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
        },
        delete_saved_plan: async function(plan_id){
            this.page_loading = true;
            let payload = {
                campaign_plan_id: plan_id,
                project_id: this.project_id,
                conditions: null,
            };

            let token = await this.$auth.getTokenSilently();
            let result = await planning_api_caller
            .delete_campaign_plan_data(token, payload)
            .then((resp) => {
                if (resp["success"]) {
                return resp["data"];
                }
                return false;
            })
            .catch((e) => {
                console.log("error", e);
                return false;
            });

            if (!result || !result["campaign_plan_id"]) {
                this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
            }
            await this.get_available_campaign_plans();
            this.page_loading = false;
        },
        open_saved_plan(plan_id, type){
            
            this.$emit("update_campaign_plan_id", plan_id, (type === 'SAVED'));
        }
    }
  };
  </script>
  
  <style>
    .data_breakdown_chip {
        font-family: 'Poppins' !important;
        font-weight: 600 ;
        font-size: 0.85em;
        width: 150px;
    }
  </style>
  